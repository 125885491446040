import React, { useEffect } from "react"
import { Box, Container, Text, useColorMode } from "@chakra-ui/react"
import Layout from "../components/Layout/Layout"
import HeroSection from "../components/HeroSection"

const Impressum = () => {
  return (
    <Layout title='Impressum' colorMode='light'>
      <Box bg='brand.pearl'>
        <Container variant='layoutContainer' pt={{ sm: 24, base: 20 }} px={{ md: 8, base: 4 }}>
          <HeroSection
            alt='Band Majanko vor einer Hafenmauer'
            bgColor='brand.sand'
            justifyContent='center'
            minH={{ md: '650px', base: '400px' }}
            maxH={{ md: '650px', base: '400px' }}
            video={false}
            mediaOpacity={1}
            mediaSrc='tree.png'
            parallaxOffsetEnd={0}
            parallaxOffsetStart={0}
          >
          </HeroSection>
        </Container>

        <Container variant='layoutContainer' bg='brand.pearl' px={{ md: 8, base: 0 }}>
          <Box px={8} py={16}>
            <Text textStyle='h1' mb={8}>Impressum</Text>
            <Box maxW='2xl'>
              <Text as='h2' textStyle='h3' mb={8}>Angaben gemäß §5 TMG</Text>
              <Text textStyle='p' mb={8}>
                Majanko Bauer <br></br>
                Singer & Songwriter <br></br>
                Postfach:
                <br></br>
                PLZ: 26352
                <br></br>
                Nr: 1218
              </Text>
              <Text as='h2' textStyle='h3' mb={8}>Kontakt</Text>
              <Text textStyle='p'>
                Telefon: <a style={{ textDecoration: 'underline' }} href='tel:015901462820'>0159 01462820</a> <br></br>
                E-Mail: <a style={{ textDecoration: 'underline' }} href='mailto:majanko.music@gmail.com'>majanko.music@gmail.com</a>
              </Text>
            </Box>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default Impressum